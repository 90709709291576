// Import React and the CSS file
import React, { useState, useEffect, useLayoutEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../helpers/AuthProvider';
import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';

import WaveSurfer from 'wavesurfer.js';

import useWindowSize from '../helpers/useWindowSize';
import './LandingPage4.css';

import personalityImageFull from '../../assets/images/personality-full-image.jpg'
import sidebarImage from '../../assets/images/ai-chat.png';
import voiceImage from '../../assets/images/ai-voice-2.png';
import personalityImage from '../../assets/images/ai-personality.png';
import videoImage from '../../assets/images/ai-video.png';
import searchImage from '../../assets/images/ai-search.png';
import quickActionsImage from '../../assets/images/ai-quick-actions.png';

import copilotMascotImage from '../../assets/images/copilot-mascot.png';

import alloyAudio from '../../assets/audio/TTS-test-alloy.mp3';
import echoAudio from'../../assets/audio/TTS-test-echo.mp3';
import fableAudio from '../../assets/audio/TTS-test-fable.mp3';
import onyxAudio from '../../assets/audio/TTS-test-onyx.mp3';
import novaAudio from '../../assets/audio/TTS-test-nova.mp3';
import shimmerAudio from '../../assets/audio/TTS-test-shimmer.mp3';


// Artist showcase images
import portraitAbstractShapes from '../../assets/artistShowcase/artist-showcase-portrait-abstract-shapes.jpg';
import portraitAbstractShapes2 from '../../assets/artistShowcase/artist-showcase-portrait-abstract-shapes-2.jpg';
import portraitBallerina from '../../assets/artistShowcase/artist-showcase-portrait-ballerina.jpg';
import portraitBallerina2 from '../../assets/artistShowcase/artist-showcase-portrait-ballerina-2.jpg';
import portraitCyberpunkVisorGuy from '../../assets/artistShowcase/artist-showcase-portrait-cyberpunk-visor-guy.jpg';
import portraitCyberpunkVisorGuy2 from '../../assets/artistShowcase/artist-showcase-portrait-cyberpunk-visor-guy-2.jpg';
import portraitCyberpunkVisorGuy3 from '../../assets/artistShowcase/artist-showcase-portrait-cyberpunk-visor-guy-3.jpg';
import portraitDapperOlderGuy from '../../assets/artistShowcase/artist-showcase-portrait-dapper-older-guy.jpg';
import portraitDarkCurlyHair from '../../assets/artistShowcase/artist-showcase-portrait-dark-curly-hair.jpg';
import portraitElderlyWoman from '../../assets/artistShowcase/artist-showcase-portrait-elderly-woman.jpg';
import portraitElderlyWoman2 from '../../assets/artistShowcase/artist-showcase-portrait-elederly-woman-2.jpg';
import portraitFemaleWarrior from '../../assets/artistShowcase/artist-showcase-portrait-female-warrior.jpg';
import portraitFemaleWarrior2 from '../../assets/artistShowcase/artist-showcase-portrait-female-warrior-2.jpg';
import portraitGraffitiArtist from '../../assets/artistShowcase/artist-showcase-portrait-graffiti-artist.jpg';
import portraitGraffitiArtist2 from '../../assets/artistShowcase/artist-showcase-portrait-graffiti-artist-2.jpg';
import portraitJazzMusician from '../../assets/artistShowcase/artist-showcase-portrait-jazz-musician.jpg';
import portraitMiddleAgedTraveller from '../../assets/artistShowcase/artist-showcase-portrait-middle-aged-traveller.jpg';
import portraitMiddleAgedTraveller2 from '../../assets/artistShowcase/artist-showcase-portrait-middle-aged-traveller-2.jpg';
import portraitRenaissanceWoman from '../../assets/artistShowcase/artist-showcase-portrait-renaissance-woman.jpg';
import portraitRenaissanceWoman2 from '../../assets/artistShowcase/artist-showcase-portrait-renaissance-woman-2.jpg';
import portraitSteampunk from '../../assets/artistShowcase/artist-showcase-portrait-steampunk.jpg';
import portraitSurrealistFlowy from '../../assets/artistShowcase/artist-showcase-portrait-surrealist-flowy.jpg';
import portraitSurrealistSpaceFlowers from '../../assets/artistShowcase/artist-showcase-portrait-surrealist-girl-space-flowers.jpg';



import animalElephant from '../../assets/artistShowcase/artist-showcase-animal-elephant.jpg';
import animalLion from '../../assets/artistShowcase/artist-showcase-animal-lion.jpg';

import landscapeCanada from '../../assets/artistShowcase/artist-showcase-landscape-canada.jpg';
import landscapeCoastal from '../../assets/artistShowcase/artist-showcase-landscape-coastal.jpg';
import landscapeJungle from '../../assets/artistShowcase/artist-showcase-landscape-jungle.jpg';

import cartoonHoverboard from '../../assets/artistShowcase/artist-showcase-cartoon-hoverboard.jpg';
import cartoonPigtailGirl from '../../assets/artistShowcase/artist-showcase-cartoon-pigtail-girl.jpg';
import cartoonSuperheroGirl from '../../assets/artistShowcase/artist-showcase-cartoon-superhero-girl.jpg';
import cartoonSuperheroGirl2 from '../../assets/artistShowcase/artist-showcase-cartoon-superhero-girl-2.jpg';
import cartoonTypewriter from '../../assets/artistShowcase/artist-showcase-cartoon-typewriter.jpg';

import drawingHandClock from '../../assets/artistShowcase/artist-showcase-drawing-hand-clock.jpg';
import drawingMagicBook from '../../assets/artistShowcase/artist-showcase-drawing-magic-book.jpg';
import drawingTypewriter from '../../assets/artistShowcase/artist-showcase-drawing-typewriter.jpg';

import animeSamurai from '../../assets/artistShowcase/artist-showcase-anime-samurai.jpg';
import animeSpaceshipCockpit from '../../assets/artistShowcase/artist-showcase-anime-spaceship-cockpit.jpg';
import animeWarriorCherryBlossoms from '../../assets/artistShowcase/artist-showcase-anime-warrior-cherry-blossoms.jpg';
import animeWarriorCherryBlossoms2 from '../../assets/artistShowcase/artist-showcase-anime-warrior-cherry-blossoms-2.jpg';

import posterSpace from '../../assets/artistShowcase/artist-showcase-poster-space.jpg';
import posterMusicFestival from '../../assets/artistShowcase/artist-showcase-poster-music-festival.jpg';
import posterScifiCity from '../../assets/artistShowcase/artist-showcase-poster-sci-fi-city-robot.jpg';
import posterVintageRobot from '../../assets/artistShowcase/artist-showcase-poster-vintage-robot-sci-fi.jpg';

import digitalArtCyberpunkCity from '../../assets/artistShowcase/artist-showcase-digital-art-cyberpunk-city.jpg';
import digitalArtFloatingIsland from '../../assets/artistShowcase/artist-showcase-digital-art-floating-island.jpg';
import digitalArtUnderwaterCity from '../../assets/artistShowcase/artist-showcase-digital-art-underwater-city.jpg';

import logoDroneDelivery from '../../assets/artistShowcase/artist-showcase-logo-drone-delivery-aerofleet.jpg';
import logoNeuronix from '../../assets/artistShowcase/artist-showcase-logo-neuronix.jpg';
import logoSolaris from '../../assets/artistShowcase/artist-showcase-logo-solar-energy-solaris.jpg';

import architectureGreenCity from '../../assets/artistShowcase/artist-showcase-architecture-green-city.jpg';
import architectureGlassTowers from '../../assets/artistShowcase/artist-showcase-architecture-glass-towers.jpg';
import architectureCliffsideVilla from '../../assets/artistShowcase/artist-showcase-architecture-cliffside-villa.jpg';
import architectureCliffsideVilla2 from '../../assets/artistShowcase/artist-showcase-architecture-cliffside-villa-2.jpg';

import copilotImage from '../../assets/images/copilot.png';
import studioImage from '../../assets/images/design-studio-inspo.jpeg';
import nightSkyImage from '../../assets/images/night-sky-sun.png';

import missionImage from '../../assets/images/mission-midjourney.png';
import privacyImage from '../../assets/images/privacy-midjourney.png';
import ethosImage from '../../assets/images/ethos-midjourney3.png';

gsap.registerPlugin(ScrollTrigger);

function LandingPage4() {
    useEffect(() => {
        const handleFontsLoaded = () => {
          document.body.classList.add('fonts-loaded');
        };
      
        if (document.fonts && document.fonts.ready) {
          document.fonts.ready.then(handleFontsLoaded);
        } else {
          window.addEventListener('load', handleFontsLoaded);
          return () => {
            window.removeEventListener('load', handleFontsLoaded);
          };
        }
    }, []);


    // After sign in
    const { user } = useAuth(); // Use the user object from AuthContext
    const navigate = useNavigate(); // Hook for navigation

    useEffect(() => {
        if (user) { // If the user object is not null, it means the user is authenticated
            console.log("User is authenticated, navigating to studio...");
            navigate('/studio');
        }
    }, [user, navigate]); // Depend on user and navigate to ensure updates trigger the effect


    const { width } = useWindowSize();

    // Scroll banner
    const [showBanner, setShowBanner] = useState(false);

    const handleScroll = () => {
        if (window.scrollY > 0) {
            setShowBanner(true);
        } else {
            setShowBanner(false);
        }
    };

    useEffect(() => {
        window.addEventListener('scroll', handleScroll);
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);



    // Nav links
    const scrollToSection = (selector, offset = 0) => {
        const element = document.querySelector(selector);
        if (element) {
            const topPos = element.getBoundingClientRect().top + window.pageYOffset - offset;
            window.scrollTo({
                top: topPos,
                behavior: 'smooth'
            });
        }
    };



    // Carousel
/*
    const items = [
        { title: "Search", body: "AI enhanced search", imgSrc: searchImage },
        { title: "Sidebar", body: "AI for every website", imgSrc: sidebarImage },
        { title: "Voice", body: "6 tutor voices", imgSrc: voiceImage },
        { title: "Personality", body: "4 distinct personalities", imgSrc: personalityImage },
        { title: "Video", body: "AI video processing", imgSrc: videoImage },
        { title: "Quick-actions", body: "A revolution at your fingertips", imgSrc: quickActionsImage }
    ];
    
    const carouselRef = useRef(null);
    const [showLeftButton, setShowLeftButton] = useState(false);
    const [showRightButton, setShowRightButton] = useState(true);

    const checkScrollPosition = () => {
        if (carouselRef.current) {
            const { scrollLeft, scrollWidth, clientWidth } = carouselRef.current;
            setShowLeftButton(scrollLeft > 0);
            setShowRightButton(scrollLeft < scrollWidth - clientWidth);
        }
    };

    const scrollLeft = () => {
        if (carouselRef.current) {
            carouselRef.current.scrollBy({ left: -1000, behavior: 'smooth' });
            setTimeout(checkScrollPosition, 400); // Adjust timing based on scroll behavior
        }
    };

    const scrollRight = () => {
        if (carouselRef.current) {
            carouselRef.current.scrollBy({ left: 1000, behavior: 'smooth' });
            setTimeout(checkScrollPosition, 400); // Adjust timing based on scroll behavior
        }
    };

    // Setup an event listener for the carousel scroll
    useEffect(() => {
        const element = carouselRef.current;
        element.addEventListener('scroll', checkScrollPosition);
        checkScrollPosition(); // Initial check

        return () => element.removeEventListener('scroll', checkScrollPosition);
    }, []);

    // Center carousel on initial load
    useEffect(() => {
        if (carouselRef.current) {
            const { scrollWidth, clientWidth } = carouselRef.current;
            const centerPosition = (scrollWidth - clientWidth) / 2;
            carouselRef.current.scrollLeft = centerPosition;
            checkScrollPosition(); // Update button visibility based on initial scroll position
        }
    }, []);
*/

    const meetCopilotTextRef = useRef(null);
    useEffect(() => {
        if (meetCopilotTextRef.current) {
            // Initial fade-in for title
            if (window.scrollY === 0) {
                gsap.fromTo(
                    meetCopilotTextRef.current,
                    { opacity: 0, scale: 0.8 },
                    {
                        duration: 1.8,
                        opacity: 1,
                        scale: 1,
                        ease: 'expo.inOut',
                    }
                );
            }
        }
    }, []);

    // Load in animations
    // Refs for each feature container
    const meetCopilotSubheadingContainer = useRef(null);
    const copilotTextRef = useRef(null);
    const appleCarouselInnerContainerRef = useRef(null);
    const copilotTile1Ref = useRef(null);
    const copilotTile2Ref = useRef(null);
    const copilotTile3Ref = useRef(null);
    const copilotTile4Ref = useRef(null);
    const studioImgRef = useRef(null);
    const studioTextRef = useRef(null);
    const youtubeTextRef = useRef(null);
    const youtubeImg1Ref = useRef(null);
    const youtubeImg2Ref = useRef(null);
    const aboutTitleRef = useRef(null);
    const aboutContainerRef = useRef(null);
    const testimonialTitleRef = useRef(null);
    const testimonial1Ref = useRef(null);
    const testimonial2Ref = useRef(null);
    const testimonial3Ref = useRef(null);
    const testimonial4Ref = useRef(null);
    const testimonial5Ref = useRef(null);
    const testimonial6Ref = useRef(null);
    const testimonial7Ref = useRef(null);
    const testimonial8Ref = useRef(null);
    const testimonialButtonRef = useRef(null);
    const plansTitleRef = useRef(null);
    const novicePlanCardRef = useRef(null);
    const proPlanCardRef = useRef(null);
    const ultraPlanCardRef = useRef(null);

    const [isPillInView, setIsPillInView] = useState(false);

    useEffect(() => {
        const observer = new IntersectionObserver(
          (entries) => {
            entries.forEach((entry) => {
              setIsPillInView(entry.isIntersecting);
            });
          },
          {
            root: null,
            rootMargin: '0px',
            threshold: 0.5,
          }
        );
      
        if (meetCopilotSubheadingContainer.current) {
          observer.observe(meetCopilotSubheadingContainer.current);
        }
      
        return () => {
          if (meetCopilotSubheadingContainer.current) {
            observer.unobserve(meetCopilotSubheadingContainer.current);
          }
        };
    }, []);

    useEffect(() => {
        if (meetCopilotSubheadingContainer.current) {
          gsap.set(meetCopilotSubheadingContainer.current, {
            y: 100,
            opacity: 0,
            width: 0,
          });
        }
    }, []);

    useEffect(() => {
        if (meetCopilotSubheadingContainer.current) {
          const element = meetCopilotSubheadingContainer.current;
          gsap.killTweensOf(element);
      
          if (isPillInView) {
            // Animate in
            gsap.fromTo(
              element,
              {
                y: 100,
                opacity: 0,
                width: 0,
              },
              {
                y: 0,
                opacity: 1,
                width: 'auto',
                duration: 1.5,
                ease: 'elastic.out(0.3, 0.3)',
                onComplete: () => {
                  updatePillPosition();
                },
              }
            );
          } else {
            // Animate out
            gsap.fromTo(
              element,
              {
                y: 0,
                opacity: 1,
                width: 'auto',
              },
              {
                y: 200,
                opacity: 0,
                width: 0,
                duration: 1,
                ease: 'power2.in',
              }
            );
          }
        }
    }, [isPillInView]);

    /*
    useEffect(() => {
        const observer = new IntersectionObserver(
            (entries) => {
                entries.forEach(entry => {
                    if (entry.isIntersecting) {
                        const delayClass = entry.target.getAttribute('data-animation-delay');
                        entry.target.classList.add('feature-animation', delayClass);
                        observer.unobserve(entry.target);
                    }
                });
            },
            {
                root: null,
                rootMargin: '0px',
                threshold: 0.5
            }
        );
    
        const refs = [meetCopilotTextRef, meetCopilotSubheadingContainer, copilotTextRef, copilotTile1Ref, copilotTile2Ref, copilotTile3Ref, copilotTile4Ref, artistTextRef, studioImgRef, studioTextRef, youtubeTextRef, mindMapImgRef, aboutTitleRef, testimonialTitleRef, testimonial1Ref, testimonial2Ref, testimonial3Ref, testimonial4Ref, testimonial5Ref, testimonial6Ref, testimonial7Ref, testimonial8Ref, testimonialButtonRef, plansTitleRef, novicePlanCardRef, proPlanCardRef, ultraPlanCardRef];
        refs.forEach(ref => {
            if (ref.current) {
                observer.observe(ref.current);
            }
        });
    
        return () => {
            refs.forEach(ref => {
                if (ref.current) observer.unobserve(ref.current);
            });
        };
    }, []);
    */

    // Animate in sidebar/copilot carousel
    useEffect(() => {
        const observer = new IntersectionObserver(
            (entries) => {
                entries.forEach(entry => {
                    if (entry.isIntersecting) {
                        // GSAP timeline to animate with staggered/overlapping animations
                        const tl = gsap.timeline({
                            defaults: { duration: 1, ease: 'power2.out' }
                        });
    
                        // Add each tile to the timeline with staggered delays
                        tl.fromTo(copilotTile1Ref.current, { opacity: 0, y: 40 }, { opacity: 1, y: 0 })
                          .fromTo(copilotTile2Ref.current, { opacity: 0, y: 40 }, { opacity: 1, y: 0 }, '-=0.8')
                          .fromTo(copilotTile3Ref.current, { opacity: 0, y: 40 }, { opacity: 1, y: 0 }, '-=0.8')
                          .fromTo(copilotTile4Ref.current, { opacity: 0, y: 40 }, { opacity: 1, y: 0 }, '-=0.8');
    
                        // Stop observing the parent container after the animation
                        observer.unobserve(entry.target);
                    }
                });
            },
            {
                root: null,
                rootMargin: '0px',
                threshold: 0.5,  // Trigger when 50% of the container is visible
            }
        );
    
        // Observe only the apple-carousel-inner-container for animation trigger
        const parentContainerRef = appleCarouselInnerContainerRef.current; // Make sure to define this ref
    
        if (parentContainerRef) {
            observer.observe(parentContainerRef);
        }
    
        return () => {
            if (parentContainerRef) observer.unobserve(parentContainerRef);
        };
    }, []);

    // Animate in all other elements
    useEffect(() => {
        const observer = new IntersectionObserver(
            (entries) => {
                entries.forEach(entry => {
                    if (entry.isIntersecting) {
                        const delay = entry.target.getAttribute('data-animation-delay') || 0;
    
                        // GSAP timeline to animate with staggered/overlapping animations
                        const tl = gsap.timeline({
                            defaults: { duration: 1, ease: 'power2.out' }
                        });
    
                        // GSAP animation for each target with delay from data attribute
                        tl.fromTo(
                            entry.target,
                            { opacity: 0, y: 20 }, // Starting state
                            { opacity: 1, y: 0 },   // Final state
                            `+=${delay}` // Use the delay from the data attribute
                        );
    
                        // Stop observing the target after the animation
                        observer.unobserve(entry.target);
                    }
                });
            },
            {
                root: null,
                rootMargin: '0px',
                threshold: 0.5,  // Trigger when 50% of the element is visible
            }
        );
    
        // Ref for elements
        const refs = [meetCopilotTextRef, meetCopilotSubheadingContainer, copilotTextRef, studioImgRef, studioTextRef, youtubeTextRef, youtubeImg1Ref, youtubeImg2Ref, aboutTitleRef, testimonialTitleRef, testimonial1Ref, testimonial2Ref, testimonial3Ref, testimonial4Ref, testimonial5Ref, testimonial6Ref, testimonial7Ref, testimonial8Ref, testimonialButtonRef, plansTitleRef, novicePlanCardRef, proPlanCardRef, ultraPlanCardRef];
        
        refs.forEach(ref => {
            if (ref.current) observer.observe(ref.current);
        });
    
        return () => {
            refs.forEach(ref => {
                if (ref.current) observer.unobserve(ref.current);
            });
        };
    }, []);




    // Meet Copilot Section
    const [activeIndex, setActiveIndex] = useState(0);

    const subheadings = ['What I am', 'Personality', 'Voice', 'About you', 'Privacy'];
    const subheadingRefs = useRef([]);
    const slidingPillRef = useRef(null);
    const contentRefs = useRef([]);
    const tileRefs = useRef([]);
    const prevActiveIndexRef = useRef(activeIndex);
  
    const updatePillPosition = () => {
      if (subheadingRefs.current[activeIndex] && slidingPillRef.current) {
        const activeSubheading = subheadingRefs.current[activeIndex];
        const parentRect = activeSubheading.parentElement.getBoundingClientRect();
        const subheadingRect = activeSubheading.getBoundingClientRect();
  
        const left = subheadingRect.left - parentRect.left;
        const width = subheadingRect.width;
  
        gsap.to(slidingPillRef.current, {
          duration: 0.4,
          left: left,
          width: width,
          ease: 'power2.out',
        });
      }
    };
  
    useEffect(() => {
      updatePillPosition();
    }, [activeIndex]);
  
    useEffect(() => {
      const handleFontsLoaded = () => {
        updatePillPosition();
      };
  
      if (document.fonts && document.fonts.ready) {
        document.fonts.ready.then(handleFontsLoaded);
      } else {
        window.addEventListener('load', handleFontsLoaded);
        return () => {
          window.removeEventListener('load', handleFontsLoaded);
        };
      }
    }, []);
  
    useEffect(() => {
        const initialTiles = tileRefs.current[0];
    
        // Check if initial tiles exist
        if (initialTiles && initialTiles.tile1 && initialTiles.tile2 && initialTiles.tile3) {
            const tl = gsap.timeline();
            
            // Animate the tiles growing into view
            tl.fromTo(
                [initialTiles.tile1, initialTiles.tile2, initialTiles.tile3],
                {
                    opacity: 0,
                    scale: 0, // Start with scale 0 (invisible)
                },
                {
                    duration: 0.6,
                    opacity: 1,
                    scale: 1, // Grow to full size
                    ease: 'power2.out',
                    stagger: 0.1, // Stagger the tiles' animations
                }
            );
        }
    }, []); // Empty dependency array ensures this runs only once on initial load
    
    useLayoutEffect(() => {
        const prevActiveIndex = prevActiveIndexRef.current;
        const currentActiveIndex = activeIndex;
    
        if (prevActiveIndex === currentActiveIndex) return;
    
        const prevContent = contentRefs.current[prevActiveIndex];
        const currentContent = contentRefs.current[currentActiveIndex];
    
        const tl = gsap.timeline();
    
        // Animate previous tiles out
        if (prevContent) {
            const prevTiles = tileRefs.current[prevActiveIndex];
            if (prevTiles && prevTiles.tile1 && prevTiles.tile2 && prevTiles.tile3) {
                tl.to(
                    [prevTiles.tile1, prevTiles.tile2, prevTiles.tile3],
                    {
                        duration: 0.3,
                        opacity: 0,
                        scale: 0,
                        ease: 'power2.in',
                        stagger: 0.05,
                    }
                ).add(() => {
                    // After animation completes, set display: none
                    prevContent.style.zIndex = '-1';
                });
            } else {
                prevContent.style.zIndex = '-1';
            }
        }
    
        // Set display: flex on current content before animating in
        if (currentContent) {
            currentContent.style.zIndex = '1';
    
            const tiles = tileRefs.current[currentActiveIndex];
            if (tiles && tiles.tile1 && tiles.tile2 && tiles.tile3) {
                tl.fromTo(
                    [tiles.tile1, tiles.tile2, tiles.tile3],
                    {
                        opacity: 0,
                        scale: 0,
                    },
                    {
                        duration: 0.3,
                        opacity: 1,
                        scale: 1,
                        ease: 'power2.out',
                        stagger: 0.05,
                    },
                    '-=0.15'
                );
            }
        }
    
        prevActiveIndexRef.current = currentActiveIndex;
    
        return () => {
            tl.kill();
        };
    }, [activeIndex]);
    
    useEffect(() => {
        contentRefs.current.forEach((content, index) => {
            if (content) {
                if (index === activeIndex) {
                    content.style.zIndex = '1';
                } else {
                    content.style.zIndex = '-1';
                }
            }
        });
    }, []);



    // Personality accordion
    // Map of personality sections to gradient backgrounds
    const gradientBackgrounds = {
        'personality-1': 'linear-gradient(199deg, #77c2cf, #475d90)',
        'personality-2': 'linear-gradient(199deg, rgb(88 131 117), rgb(38 68 55))',
        'personality-3': 'linear-gradient(199deg, rgb(189 104 104), rgb(98 37 73))',
        'personality-4': 'linear-gradient(199deg, rgb(144 168 228), rgb(79 55 99))',
    };
  
    // State to keep track of the open section
    const [openPersonalitySection, setOpenPersonalitySection] = useState('personality-1');

    // Refs for each personality section
    const personalityRefs = useRef({
        'personality-1': null,
        'personality-2': null,
        'personality-3': null,
        'personality-4': null,
    });

    // Ref for the gradient overlay
    const gradientOverlayRef = useRef(null);

    useEffect(() => {
        if (tileRefs.current[1] && tileRefs.current[1].tile3) {
          const parentElement = tileRefs.current[1].tile3;
          parentElement.style.background = gradientBackgrounds[openPersonalitySection];
        }
    }, []);

    const prevOpenPersonalitySectionRef = useRef(openPersonalitySection);

    useEffect(() => {
      if (prevOpenPersonalitySectionRef.current !== openPersonalitySection) {
        if (gradientOverlayRef.current && tileRefs.current[1] && tileRefs.current[1].tile3) {
          const parentElement = tileRefs.current[1].tile3;
    
          // Parent element's background remains as the previous gradient
          const prevGradient = gradientBackgrounds[prevOpenPersonalitySectionRef.current];
          parentElement.style.background = prevGradient;
    
          // Set the new gradient on the overlay
          const newGradient = gradientBackgrounds[openPersonalitySection];
          gradientOverlayRef.current.style.background = newGradient;
    
          // Reset overlay opacity to 0
          gradientOverlayRef.current.style.opacity = 0;
    
          // Force reflow
          void gradientOverlayRef.current.offsetWidth;
    
          // Animate overlay opacity to 1
          gradientOverlayRef.current.style.opacity = 1;
    
          // After transition, update parent background and reset overlay opacity
          setTimeout(() => {
            if (gradientOverlayRef.current && parentElement) {
              parentElement.style.background = newGradient;
              gradientOverlayRef.current.style.opacity = 0;
            }
          }, 500); // Duration matches the CSS transition time
    
          // Update the previous section reference
          prevOpenPersonalitySectionRef.current = openPersonalitySection;
        }
      }
    }, [openPersonalitySection]);

    const togglePersonalitySection = (section) => {
        if (openPersonalitySection === section) return; // Do nothing if the clicked section is already open

        // Remove 'open' class and reset styles from the previously open section
        const prevSection = openPersonalitySection;
        if (prevSection && personalityRefs.current[prevSection]) {
            const prevSectionEl = personalityRefs.current[prevSection];
            prevSectionEl.classList.remove('open');

            // Reset the height of the previous content to 0
            const prevContent = prevSectionEl.querySelector('.personality-accordion-text');
            if (prevContent) {
                prevContent.style.height = '0px';
                prevContent.style.paddingTop = '0';
            }
        }

        // Add 'open' class and set styles for the clicked section
        if (personalityRefs.current[section]) {
            const currentSectionEl = personalityRefs.current[section];
            currentSectionEl.classList.add('open');

            // Expand the height of the current content to its scrollHeight
            const currentContent = currentSectionEl.querySelector('.personality-accordion-text');
            if (currentContent) {
                const contentHeight = currentContent.scrollHeight;
                currentContent.style.height = `${contentHeight}px`;
                currentContent.style.paddingTop = '15px';
            }
        }

        // Update the state to the new open section
        setOpenPersonalitySection(section);
    };

    useEffect(() => {
        const initializeAccordion = () => {
          Object.keys(personalityRefs.current).forEach((sectionKey) => {
            const sectionEl = personalityRefs.current[sectionKey];
            if (sectionEl) {
              const contentEl = sectionEl.querySelector('.personality-accordion-text');
      
              if (sectionKey === openPersonalitySection) {
                // Open the default section
                sectionEl.classList.add('open');
                if (contentEl) {
                  const contentHeight = contentEl.scrollHeight;
                  contentEl.style.height = `${contentHeight}px`;
                  contentEl.style.paddingTop = '15px';
                }
              } else {
                // Ensure other sections are closed
                sectionEl.classList.remove('open');
                if (contentEl) {
                  contentEl.style.height = '0px';
                  contentEl.style.paddingTop = '0';
                }
              }
            }
          });
        };
      
        if (document.fonts && document.fonts.ready) {
          document.fonts.ready.then(initializeAccordion);
        } else {
          // Fallback for browsers that don't support document.fonts
          window.addEventListener('load', initializeAccordion);
          return () => {
            window.removeEventListener('load', initializeAccordion);
          };
        }
    }, []);



    // Voice section
    const waveSurferRefs = useRef({});
    const [playingVoice, setPlayingVoice] = useState(null);  // Track the currently playing voice
    const [isPlaying, setIsPlaying] = useState({}); // Track the play/pause state of each voice

    const audioFiles = {
        alloy: alloyAudio,
        echo: echoAudio,
        fable: fableAudio,
        onyx: onyxAudio,
        nova: novaAudio,
        shimmer: shimmerAudio
    };
    
    useEffect(() => {
        const voices = ['alloy', 'echo', 'fable', 'onyx', 'nova', 'shimmer'];
    
        voices.forEach((voice) => {
            const waveSurfer = WaveSurfer.create({
                container: `#waveform-${voice}`,
                fillParent: true,
                height: '50',
                barHeight: '1',
                waveColor: '#f2f2f2',
                progressColor: '#ffffff80',
                cursorWidth: '0',
                barWidth: '2',
                barGap: '2',
                barRadius: '10'
            });
    
            // Load the corresponding audio file
            waveSurfer.load(audioFiles[voice]);
    
            // Save the waveSurfer instance in refs for later access
            waveSurferRefs.current[voice] = waveSurfer;

            // Event listener to reset the play icon after the audio ends
            waveSurfer.on('finish', () => {
                setIsPlaying(prevState => ({ ...prevState, [voice]: false }));
                setPlayingVoice(null); // Reset the currently playing voice
            });
        });
    
        return () => {
            voices.forEach((voice) => {
                if (waveSurferRefs.current[voice]) {
                    waveSurferRefs.current[voice].destroy();
                }
            });
        };
    }, []);

    const handlePlayPause = (voice) => {
        const waveSurfer = waveSurferRefs.current[voice];

        if (waveSurfer.isPlaying()) {
            waveSurfer.pause();
            setIsPlaying(prevState => ({ ...prevState, [voice]: false })); // Set paused state for this voice
            setPlayingVoice(null); // No currently playing voice
        } else {
            // Pause any currently playing voice
            if (playingVoice && playingVoice !== voice) {
                waveSurferRefs.current[playingVoice].pause();
                setIsPlaying(prevState => ({ ...prevState, [playingVoice]: false }));
            }

            // Play the selected voice
            waveSurfer.play();
            setIsPlaying(prevState => ({ ...prevState, [voice]: true })); // Set playing state for this voice
            setPlayingVoice(voice); // Set this voice as currently playing
        }
    };



    // About You section
    useEffect(() => {
        const questions = [
          '#question-1',
          '#question-6',
          '#question-12',
          '#question-15',
          '#question-2',
          '#question-7',
          '#question-11',
          '#question-18',
          '#question-5',
          '#question-8',
          '#question-14',
          '#question-19',
          '#question-4',
          '#question-9',
          '#question-13',
          '#question-16',
          '#question-3',
          '#question-10',
          '#question-17'
        ];

        const baseDelay = 0; // Base delay between each question's start
        const incrementDelay = 1.4; // Increment delay for each subsequent question
    
        questions.forEach((question, index) => {
          const duration = 30 + Math.random() * 1; 
          const delay = baseDelay + (incrementDelay * index); // Progressive delay based on index
          
          gsap.to(question, {
            x: '-50em', // Move it completely out of the container on the right
            duration: duration, 
            ease: 'linear',
            repeat: -1, // Infinite loop
            delay: delay,
            onRepeat: function () {
              // Reset position when animation restarts
              gsap.set(question, { x: '100%' });
            }
          });
        });
    }, []);



    const appleCarouselOuterContainerRef = useRef(null);
    const netflixBackgroundContainerRef = useRef(null); // New ref for the next section
    const artistContentContainerRef = useRef(null);

    // Copilot/Sidebar section 
    useEffect(() => {
        let ctx = gsap.context(() => {
          const updateAnimation = () => {
            const outerContainer = appleCarouselOuterContainerRef.current;
            const container = appleCarouselInnerContainerRef.current;
            const netflixContainer = netflixBackgroundContainerRef.current; // New section container
      
            if (container && outerContainer && netflixContainer) {
              const outerScrollWidth = outerContainer.scrollWidth; // Use outer container scroll width
              const viewportWidth = window.innerWidth;
              const scrollDistance = outerScrollWidth - viewportWidth;
      
              // Kill any existing animations and ScrollTriggers for the container
              gsap.killTweensOf(container);
              ScrollTrigger.getById('carouselScroll')?.kill();
      
              // Calculate the total scroll distance for the scale/fade after horizontal scrolling
              const scaleFadeScrollDistance = 500; // Distance for the scale/fade animation
              const totalScrollDistance = scrollDistance + scaleFadeScrollDistance;
      
              // Create a timeline for the animation
              const tl = gsap.timeline({
                scrollTrigger: {
                  id: 'carouselScroll',
                  trigger: outerContainer, // Trigger on the outer container
                  start: 'top top+=5%',
                  end: `+=${totalScrollDistance}`,
                  scrub: true,
                  pin: true,
                  anticipatePin: 1,
                  // markers: true, // Uncomment for debugging
                },
              });
      
              // Horizontal scrolling for the carousel
              tl.to(container, {
                x: -scrollDistance, // Scroll the inner container
                ease: 'none',
                duration: scrollDistance / totalScrollDistance, // Full scroll duration
              });
      
              // Scaling and fading out of the carousel after horizontal scroll finishes
              tl.to(outerContainer, {
                scale: 1.2,  // Scale up
                opacity: 0,  // Fade out
                ease: 'none',
                duration: scaleFadeScrollDistance / totalScrollDistance, // Scale and fade duration
              }, `+=0`);  // Start the scaling and fading after horizontal scroll finishes
      
              // Fade in the next section (netflixBackgroundContainer) as the carousel fades out
              tl.fromTo(netflixContainer, 
                { opacity: 0 }, 
                { opacity: 1, 
                  ease: 'none', 
                  duration: 1,
                  scrollTrigger: {
                    trigger: netflixContainer,
                    start: 'top top',
                    scrub: true,
                    pin: true, // Pin the next section
                    pinSpacing: false, // Remove extra space after pinning
                    // markers: true, // Uncomment for debugging
                  },
              }, '-=1'); // Start fading in the netflix section slightly before the carousel fully fades out
            }
          };
      
          // Prevent horizontal scroll during carousel animation
          const preventHorizontalScroll = (e) => {
            const container = appleCarouselInnerContainerRef.current;
            if (container && e.deltaX !== 0) {
              e.preventDefault();
            }
          };
      
          // Initialize the animation
          updateAnimation();
      
          // Add event listener for preventing horizontal scroll
          window.addEventListener('wheel', preventHorizontalScroll, { passive: false });
      
          // Add event listener for window resize to update animation
          window.addEventListener('resize', updateAnimation);
      
          // Cleanup: Remove event listeners and kill ScrollTrigger when the component unmounts
          return () => {
            window.removeEventListener('resize', updateAnimation);
            window.removeEventListener('wheel', preventHorizontalScroll);
            ScrollTrigger.getById('carouselScroll')?.kill();
          };
        }, [appleCarouselOuterContainerRef, appleCarouselInnerContainerRef, netflixBackgroundContainerRef]);
    
        // Cleanup GSAP context
        return () => {
          ctx.revert();
        };
    }, []);
    
    



    // About accordion
    const [openSection, setOpenSection] = useState('mission');
    const missionRef = useRef(null);
    const privacyRef = useRef(null);
    const ethosRef = useRef(null);

    const toggleSection = (section) => {
        // Ensure that the section cannot be closed if it's the only open section
        if (openSection === section) return;
        setOpenSection(section);
    };

    // Determine which image to display based on the open section
    const getImage = () => {
        switch(openSection) {
            case 'mission':
                return missionImage;
            case 'privacy':
                return privacyImage;
            case 'ethos':
                return ethosImage;
            default:
                return missionImage; // Default case to handle unexpected values
        }
    };    
    console.log(width);

    useEffect(() => {
        const handleScroll = () => {
            const container = aboutContainerRef.current;
            if (container) {
                const scrollTop = window.pageYOffset || document.documentElement.scrollTop;
                const containerTop = container.offsetTop; // Get the top position of the container
                const containerHeight = container.offsetHeight;
    
                // Check if the container top is in the viewport
                if (scrollTop < containerTop + containerHeight) {
                    // Calculate a scaling factor based on scroll position
                    let scale = 1 + (containerTop - scrollTop) / 3000; // Adjust this divisor to control the effect intensity
                    scale = Math.max(scale, 1); // Ensure scale does not go below 1
                    container.style.transform = `scale(${scale})`;
                } else {
                    container.style.transform = 'scale(1)';
                }
            }
        };
    
        window.addEventListener('scroll', handleScroll);
    
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    return (
        <div className="landing-page preload">
            <nav className="navbar">
                <div className="LP-navbar-left">
                    <div className="logo-container">
                        <p className="navbar-logo text-gradient">Paradigm</p>
                        <p className="navbar-logo-x">X</p>
                    </div>
                    <div className="nav-links">
                        <a href="#features" onClick={(e) => {
                            e.preventDefault();
                            scrollToSection('.copilot-container', window.innerHeight * 0.1);
                        }}>
                            <p>Feature spotlight</p>
                        </a>
                        <a href="#ethos" onClick={(e) => {
                            e.preventDefault();
                            scrollToSection('.about-title', window.innerHeight * 0.05);
                        }}>
                            <p>About us</p>
                        </a>
                        <a href="#pricing" onClick={(e) => {
                            e.preventDefault();
                            scrollToSection('.plans-section-title', window.innerHeight * 0.05);
                        }}>
                            <p>Plans and pricing</p>
                        </a>
                    </div>
                </div>
                <div className="navbar-right">
                    <a href="#helpcenter">
                        <div className="help-icon-container grey-hover-effect">
                            <i className="bi bi-question-circle"></i>
                            <span className="tooltip">Help center</span>
                        </div>
                    </a>
                    <button className="navbar-button-1 button-secondary" onClick={() => window.location.href = 'https://dev.chromecopilot.ai/api/social-auth/login/google-oauth2'}>
                        Log in
                    </button>
                    <button className="navbar-button-2 button-primary" onClick={() => window.location.href = 'https://dev.chromecopilot.ai/api/social-auth/login/google-oauth2'}>
                        {width < 480 ? 'Log in' : 'Sign up'}
                    </button>
                </div>
            </nav>

            
            <div className="main">
{/*
                <div className="title-section">
                    <p className="title">
                        What will you <span className="text-gradient">learn</span> today?
                    </p>
                    <p className="subtitle">
                        Explore and enhance your skills with a personal AI tutor.
                    </p>
                    <button className="fill-available-button main-button button-primary" onClick={() => window.open('https://dev.chromecopilot.ai/api/social-auth/login/google-oauth2')}>
                        Start learning
                    </button>
                </div>

                <div className="carousel-overlay">
                    {showLeftButton && (
                        <div className="scroll-left-button-container" onClick={scrollLeft}>
                            <div className="scroll-button-left"></div>
                        </div>
                    )}
                    {showRightButton && (
                        <div className="scroll-right-button-container" onClick={scrollRight}>
                            <div className="scroll-button-right"></div>
                        </div>
                    )}
                </div>
                <div className="carousel-main-container" ref={carouselRef}>
                    <div className="carousel-inner-container">
                        {items.map((item, index) => (
                            <div className="carousel-item" key={index}
                                style={{
                                    animation: `fadeInUp 1s ease-out ${index * 0.2}s forwards`
                                }}>
                                <div className="carousel-text-container">
                                    <p className="carousel-item-title">{item.title}</p>
                                    <p className="carousel-item-body">{item.body}</p>
                                </div>
                                <img className={item.title.toLowerCase() + "-image"} src={item.imgSrc}></img>
                            </div>
                        ))}
                    </div>
                </div>
*/}

                <div className="features-section">
                    <div className="meet-copilot-container">
                        <div className='product-text-wrapper' ref={meetCopilotTextRef}>
                            <p className="product-title">Meet Copilot.</p>
                        </div>
                        <div className='pill-subheading-container' ref={meetCopilotSubheadingContainer}>
                            <div className="sliding-pill" ref={slidingPillRef}></div>

                            {subheadings.map((subheading, index) => (
                                <div
                                key={index}
                                ref={(el) => (subheadingRefs.current[index] = el)}
                                className={`pill-subheading-item ${activeIndex === index ? 'active' : ''}`}
                                onClick={() => setActiveIndex(index)}
                                >
                                <p className='pill-subheading-text'>{subheading}</p>
                                </div>
                            ))}
                        </div>

                        <div className="feature-container-tile-full meet-copilot-feature-container">
                            <div
                                className="content-section"
                                ref={(el) => (contentRefs.current[0] = el)}
                            >
                                <div className='feature-vertical-tiles-container'>
                                    <div
                                        className='feature-tile meet-copilot-tile-1'
                                        ref={(el) => {
                                            if (!tileRefs.current[0]) tileRefs.current[0] = {};
                                            tileRefs.current[0].tile1 = el;
                                        }}
                                    >
                                        <i className="fa-solid fa-seedling"></i>
                                      <p>Your AI-powered personal growth companion.</p>
                                    </div>
                                    <div
                                        className='feature-tile meet-copilot-tile-2'
                                        ref={(el) => {
                                            if (!tileRefs.current[0]) tileRefs.current[0] = {};
                                            tileRefs.current[0].tile2 = el;
                                        }}
                                    >
                                        <p className='dark-text'>Copilot transforms your browser.</p> 
                                        <p>
                                            Seamlessly integrated into your browser for a one-to-one tutor experience. Copilot removes barriers to success while making the journey fun and addictive.
                                        </p>
                                    </div>
                                </div>
                                <div
                                    className='feature-tile meet-copilot-tile-3'
                                    ref={(el) => {
                                    if (!tileRefs.current[0]) tileRefs.current[0] = {};
                                    tileRefs.current[0].tile3 = el;
                                    }}
                                >
                                    <img className="copilot-mascot-image" src={copilotMascotImage} alt="Copilot" />
                                    <div className='overflow-clip'></div>
                                </div>
                            </div>

                            {/* Content for "Personality" */}
                            <div
                                className="content-section"
                                ref={(el) => (contentRefs.current[1] = el)}
                            >
                                <div className='feature-vertical-tiles-container'>
                                    <div
                                    className='feature-tile meet-copilot-tile-1 personality-info-tile'
                                    ref={(el) => {
                                        if (!tileRefs.current[1]) tileRefs.current[1] = {};
                                        tileRefs.current[1].tile1 = el;
                                    }}
                                    style={{opacity: 0}}
                                    >
                                        <p className='white-text'>Craft your perfect AI ally.</p> 
                                        <p className='tile-subheading'>
                                            Begin your adventure by choosing from four distinct personalities.
                                        </p>
                                    </div>
                                    <div
                                    className='feature-tile meet-copilot-tile-2 personality-image-tile'
                                    ref={(el) => {
                                        if (!tileRefs.current[1]) tileRefs.current[1] = {};
                                        tileRefs.current[1].tile2 = el;
                                    }}
                                    style={{opacity: 0}}
                                    >
                                        <img className="personality-image" src={personalityImage} alt="Personality" />
                                    </div>
                                </div>
                                <div
                                    className={`feature-tile meet-copilot-tile-3 personality-feature-tile`}
                                    ref={(el) => {
                                    if (!tileRefs.current[1]) tileRefs.current[1] = {};
                                    tileRefs.current[1].tile3 = el;
                                    }}
                                    style={{
                                        opacity: 0,
                                    }}
                                >
                                    {/* Gradient Overlay */}
                                    <div 
                                        className="gradient-overlay" 
                                        ref={gradientOverlayRef} 
                                    >
                                    </div>

                                    {/* Accordion Content */}
                                    <div className='personality-accordion-container'>
                                        <div 
                                            className='personality-section personality-1-container' 
                                            onClick={() => togglePersonalitySection('personality-1')}
                                            ref={(el) => (personalityRefs.current['personality-1'] = el)}
                                        >
                                            <div className='personality-accordion-title-container'>
                                                <p className='personality-title'>
                                                    <i className="bi bi-tsunami"></i>
                                                    Flexible
                                                </p>
                                                <div className='personality-accordion-arrow'></div>
                                            </div>
                                            {/* Conditionally render the text */}
                                            <p className='personality-accordion-text'>
                                                Adaptable, even-handed and easy-going. This personality becomes what it needs to be in the moment, moulding itself to your current problem or query.
                                            </p>
                                        </div>
                                        <div className='seperator'></div>
                                        <div
                                            className='personality-section personality-2-container'
                                            onClick={() => togglePersonalitySection('personality-2')}
                                            ref={(el) => (personalityRefs.current['personality-2'] = el)}
                                        >
                                            <div className='personality-accordion-title-container'>
                                                <p className='personality-title'>
                                                    <i className="fa-solid fa-mountain"></i>
                                                    Stoic
                                                </p>
                                                <div className='personality-accordion-arrow'></div>
                                            </div>
                                            {/* Conditionally render the text */}
                                            <p className='personality-accordion-text'>
                                                Firm, professional, and pragmatic. This personality is characterised by its gravitas and sophisticated approach to learning and self improvement.
                                            </p>
                                        </div>
                                        <div className='seperator'></div>
                                        <div 
                                            className='personality-section personality-3-container'
                                            onClick={() => togglePersonalitySection('personality-3')}
                                            ref={(el) => (personalityRefs.current['personality-3'] = el)}
                                        >
                                            <div className='personality-accordion-title-container'>
                                                <p className='personality-title'>
                                                    <i className="bi bi-fire"></i>
                                                    Intense
                                                </p>
                                                <div className='personality-accordion-arrow'></div>
                                            </div>
                                            {/* Conditionally render the text */}
                                            <p className='personality-accordion-text'>
                                                Passionate, focused, and ambitious. This personality has one thing on its mind: ensuring you become the best version of yourself. You'll have to keep up.
                                            </p>
                                        </div>
                                        <div className='seperator'></div>
                                        <div 
                                            className='personality-section personality-4-container'
                                            onClick={() => togglePersonalitySection('personality-4')}
                                            ref={(el) => (personalityRefs.current['personality-4'] = el)}
                                        >
                                            <div className='personality-accordion-title-container'>
                                                <p className='personality-title'>
                                                    <i className="bi bi-tornado"></i>
                                                    Mystical
                                                </p>
                                                <div className='personality-accordion-arrow'></div>
                                            </div>
                                            {/* Conditionally render the text */}
                                            <p className='personality-accordion-text'>
                                                Compassionate, imaginative, and playful. This personality takes a spiritual approach to expanding the mind, focusing on 'how' you learn rather than 'what'.
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            {/* Content for Voice */}
                            <div
                                className="content-section"
                                ref={(el) => (contentRefs.current[2] = el)}
                            >
                                <div className='feature-vertical-tiles-container'>
                                    <div
                                        className='feature-tile meet-copilot-tile-1 voice-info-tile'
                                        ref={(el) => {
                                            if (!tileRefs.current[2]) tileRefs.current[2] = {};
                                            tileRefs.current[2].tile1 = el;
                                        }}
                                        style={{opacity: 0}}
                                    >
                                        <p className='white-text'>Hear me speak. Listen closely.</p> 
                                        <p className='tile-subheading'>
                                            Bring your tutor to life by equiping your tutor one of our six voice options.
                                        </p>
                                    </div>
                                    <div
                                        className='feature-tile meet-copilot-tile-2'
                                        ref={(el) => {
                                            if (!tileRefs.current[2]) tileRefs.current[2] = {};
                                            tileRefs.current[2].tile2 = el;
                                        }}
                                        style={{opacity: 0}}
                                    >
                                        <img className="voice-image" src={voiceImage} alt="Voice" />
                                    </div>
                                </div>
                                <div
                                    className='feature-tile meet-copilot-tile-3 voice-feature-tile'
                                    ref={(el) => {
                                    if (!tileRefs.current[2]) tileRefs.current[2] = {};
                                    tileRefs.current[2].tile3 = el;
                                    }}
                                    style={{opacity: 0}}
                                >
                                    <div className="voice-container">
                                        <div className="alloy-container">
                                            <div className="voice-select-container">
                                                <p className="when-to-enhance-search">
                                                    Bonnie
                                                </p>
                                            </div>
                                            <div className="voice-test-container">
                                                <div 
                                                    className="play-button"
                                                    id="play-alloy"
                                                    onClick={() => handlePlayPause('alloy')}
                                                >
                                                    <i className={`bi ${isPlaying.alloy ? 'bi-stop-circle-fill' : 'bi-play-circle-fill'}`}></i>
                                                </div>
                                                <div className="sound-wave-container">
                                                    <div id="waveform-alloy" className="sound-wave"></div>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="alloy-container">
                                            <div className="voice-select-container">
                                                <p className="when-to-enhance-search">
                                                    Wilson
                                                </p>
                                            </div>
                                            <div className="voice-test-container">
                                                <div 
                                                    className="play-button"
                                                    id="play-echo"
                                                    onClick={() => handlePlayPause('echo')}
                                                >
                                                    <i className={`bi ${isPlaying.echo ? 'bi-stop-circle-fill' : 'bi-play-circle-fill'}`}></i>

                                                </div>
                                                <div className="sound-wave-container">
                                                    <div id="waveform-echo" className="sound-wave"></div>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="alloy-container">
                                            <div className="voice-select-container">
                                                <p className="when-to-enhance-search">
                                                    Kam
                                                </p>
                                            </div>
                                            <div className="voice-test-container">
                                                <div 
                                                    className="play-button"
                                                    id="play-fable"
                                                    onClick={() => handlePlayPause('fable')}
                                                >
                                                    <i className={`bi ${isPlaying.fable ? 'bi-stop-circle-fill' : 'bi-play-circle-fill'}`}></i>

                                                </div>
                                                <div className="sound-wave-container">
                                                    <div id="waveform-fable" className="sound-wave"></div>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="alloy-container">
                                            <div className="voice-select-container">
                                                <p className="when-to-enhance-search">
                                                    Jeremiah
                                                </p>
                                            </div>
                                            <div className="voice-test-container">
                                                <div 
                                                    className="play-button"
                                                    id="play-onyx"
                                                    onClick={() => handlePlayPause('onyx')}
                                                >
                                                    <i className={`bi ${isPlaying.onyx ? 'bi-stop-circle-fill' : 'bi-play-circle-fill'}`}></i>
                                                </div>
                                                <div className="sound-wave-container">
                                                    <div id="waveform-onyx" className="sound-wave"></div>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="alloy-container">
                                            <div className="voice-select-container">
                                                <p className="when-to-enhance-search">
                                                    Vienna
                                                </p>
                                            </div>
                                            <div className="voice-test-container">
                                                <div 
                                                    className="play-button"
                                                    id="play-nova"
                                                    onClick={() => handlePlayPause('nova')}
                                                >
                                                    <i className={`bi ${isPlaying.nova ? 'bi-stop-circle-fill' : 'bi-play-circle-fill'}`}></i>
                                                </div>
                                                <div className="sound-wave-container">
                                                    <div id="waveform-nova" className="sound-wave"></div>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="alloy-container">
                                            <div className="voice-select-container">
                                                <p className="when-to-enhance-search">
                                                    Heather
                                                </p>
                                            </div>
                                            <div className="voice-test-container">
                                                <div 
                                                    className="play-button"
                                                    id="play-shimmer"
                                                    onClick={() => handlePlayPause('shimmer')}
                                                >
                                                    <i className={`bi ${isPlaying.shimmer ? 'bi-stop-circle-fill' : 'bi-play-circle-fill'}`}></i>
                                                </div>
                                                <div className="sound-wave-container">
                                                    <div id="waveform-shimmer" className="sound-wave"></div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            {/* Content for About You section */}
                            <div
                                className="content-section"
                                ref={(el) => (contentRefs.current[3] = el)}
                            >
                                <div className='feature-vertical-tiles-container'>
                                    <div
                                        className='feature-tile meet-copilot-tile-1'
                                        ref={(el) => {
                                            if (!tileRefs.current[3]) tileRefs.current[3] = {};
                                            tileRefs.current[3].tile1 = el;
                                        }}
                                        style={{opacity: 0}}
                                    >
                                        <p className='white-text'>Understanding is the cornerstone.</p> 
                                        <p className='tile-subheading'>
                                            By providing key insights about yourself, Copilot can tailor its guidance to your unique journey and context.
                                        </p>
                                    </div>
                                    <div
                                        className='feature-tile meet-copilot-tile-2'
                                        ref={(el) => {
                                            if (!tileRefs.current[3]) tileRefs.current[3] = {};
                                            tileRefs.current[3].tile2 = el;
                                        }}
                                        style={{opacity: 0}}
                                    >
                                        <img className="about-you-image" src={sidebarImage} alt="About you" />
                                    </div>
                                </div>
                                <div
                                    className='feature-tile meet-copilot-tile-3 about-you-feature-tile'
                                    ref={(el) => {
                                    if (!tileRefs.current[3]) tileRefs.current[3] = {};
                                    tileRefs.current[3].tile3 = el;
                                    }}
                                    style={{opacity: 0}}
                                >
                                    <div className='about-you-questions-outer-container'>
                                        <div className='about-you-questions-inner-container'>
                                            <p className='about-you-question-text' id='question-1'>
                                                What's your name?
                                            </p>
                                            <p className='about-you-question-text' id='question-2'>
                                                Who are you?
                                            </p>
                                            <p className='about-you-question-text' id='question-3'>
                                                Who do you want to be 5 years from now?
                                            </p>
                                            <p className='about-you-question-text' id='question-4'>
                                                What's your goal?
                                            </p>
                                            <p className='about-you-question-text' id='question-5'>
                                                What motivates you?
                                            </p>
                                            <p className='about-you-question-text' id='question-6'>
                                                What's your passion?
                                            </p>
                                            <p className='about-you-question-text' id='question-7'>
                                                How old are you?
                                            </p>
                                            <p className='about-you-question-text' id='question-8'>
                                                How do you spend your time?
                                            </p>
                                            <p className='about-you-question-text' id='question-9'>
                                                What's your occupation?
                                            </p>
                                            <p className='about-you-question-text' id='question-10'>
                                                What hobbies do you have?
                                            </p>
                                            <p className='about-you-question-text' id='question-11'>
                                                What are your strengths?
                                            </p>
                                            <p className='about-you-question-text' id='question-12'>
                                                What are your weaknesses?
                                            </p>
                                            <p className='about-you-question-text' id='question-13'>
                                                How do you like to learn?
                                            </p>
                                            <p className='about-you-question-text' id='question-14'>
                                                What would you like to get better at?
                                            </p>
                                            <p className='about-you-question-text' id='question-15'>
                                                What's your dream job?
                                            </p>
                                            <p className='about-you-question-text' id='question-16'>
                                                What motivates you?
                                            </p>
                                            <p className='about-you-question-text' id='question-17'>
                                                What are the top 5 most important things in your life?
                                            </p>
                                            <p className='about-you-question-text' id='question-18'>
                                                What gives you meaning?
                                            </p>
                                            <p className='about-you-question-text' id='question-19'>
                                                What are your top 5 life goals?
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            {/* Content for Privacy */}
                            <div
                                className="content-section"
                                ref={(el) => (contentRefs.current[4] = el)}
                            >
                                <div className='feature-vertical-tiles-container'>
                                    <div
                                        className='feature-tile meet-copilot-tile-1'
                                        ref={(el) => {
                                            if (!tileRefs.current[4]) tileRefs.current[4] = {};
                                            tileRefs.current[4].tile1 = el;
                                        }}
                                        style={{opacity: 0}}
                                    >
                                        <p className='white-text'>Student-teacher confidentiality.</p> 
                                        <p className='tile-subheading'>
                                            We believe everything should stay between you and your AI.
                                        </p>
                                    </div>
                                    <div
                                        className='feature-tile meet-copilot-tile-2'
                                        ref={(el) => {
                                            if (!tileRefs.current[4]) tileRefs.current[4] = {};
                                            tileRefs.current[4].tile2 = el;
                                        }}
                                        style={{opacity: 0}}
                                    >
                                        <p className='dark-text'>
                                            We are committed to protecting your data.
                                        </p>
                                        <p>
                                            Copilot was designed with your privacy in mind. Your interactions cannot be seen, saved or stored. We will never sell your data to a third party.
                                        </p>
                                    </div>
                                </div>
                                <div
                                    className='feature-tile meet-copilot-tile-3 privacy-feature-tile'
                                    ref={(el) => {
                                    if (!tileRefs.current[4]) tileRefs.current[4] = {};
                                    tileRefs.current[4].tile3 = el;
                                    }}
                                    style={{opacity: 0}}
                                >
                                    <div className='privacy-container'>
                                        <i className="bi bi-lock-fill"></i>
                                        <div className='privacy-dots-container'>
                                            <i className="bi bi-circle-fill"></i>
                                            <i className="bi bi-circle-fill"></i>
                                            <i className="bi bi-circle-fill"></i>
                                            <i className="bi bi-circle-fill"></i>
                                            <i className="bi bi-circle-fill"></i>
                                            <i className="bi bi-circle-fill"></i>
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>


                    <div className="copilot-container">
                        <div ref={copilotTextRef} className='product-text-wrapper'>
                            <p className="feature-title">Sidebar.</p>
                            <p className="feature-title-2">For every website.</p>
                        </div>
                        <div className='apple-carousel-outer-container' ref={appleCarouselOuterContainerRef}>
                            <div ref={appleCarouselInnerContainerRef} className='apple-carousel-inner-container'>
                                <div ref={copilotTile1Ref} className="feature-container copilot-feature-container" data-animation-delay="0.2">

                                </div>
                                <div ref={copilotTile2Ref} className="feature-container copilot-feature-container" data-animation-delay="0.2">

                                </div>
                                <div ref={copilotTile3Ref} className="feature-container copilot-feature-container" data-animation-delay="0.2">

                                </div>
                                <div ref={copilotTile4Ref} className="feature-container copilot-feature-container" data-animation-delay="0.2">

                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="artist-container">
                        <div className='netflix-background-container' ref={netflixBackgroundContainerRef}>
                            <div className='netflix-gradient'></div>
                            <div className='netflix-container-perspective'>
                                <div className='netflix-container-background'>
                                    <div className='netflix-row'>
                                        <div className='netflix-thumbnail' style={{ backgroundImage: `url(${portraitDarkCurlyHair})` }}></div>
                                        <div className='netflix-thumbnail' style={{ backgroundImage: `url(${landscapeCanada})` }}></div>
                                        <div className='netflix-thumbnail' style={{ backgroundImage: `url(${cartoonHoverboard})` }}></div>
                                        <div className='netflix-thumbnail' style={{ backgroundImage: `url(${drawingHandClock})` }}></div>
                                        <div className='netflix-thumbnail' style={{ backgroundImage: `url(${animeSamurai})` }}></div>
                                        <div className='netflix-thumbnail' style={{ backgroundImage: `url(${posterSpace})` }}></div>
                                        <div className='netflix-thumbnail' style={{ backgroundImage: `url(${digitalArtCyberpunkCity})` }}></div>
                                        <div className='netflix-thumbnail' style={{ backgroundImage: `url(${logoNeuronix})` }}></div>
                                        <div className='netflix-thumbnail' style={{ backgroundImage: `url(${architectureGreenCity})` }}></div>
                                        <div className='netflix-thumbnail' style={{ backgroundImage: `url(${architectureGlassTowers})` }}></div>
                                        <div className='netflix-thumbnail' style={{ backgroundImage: `url(${portraitDarkCurlyHair})` }}></div>
                                        <div className='netflix-thumbnail' style={{ backgroundImage: `url(${landscapeCanada})` }}></div>
                                        <div className='netflix-thumbnail' style={{ backgroundImage: `url(${cartoonHoverboard})` }}></div>
                                        <div className='netflix-thumbnail' style={{ backgroundImage: `url(${drawingHandClock})` }}></div>
                                        <div className='netflix-thumbnail' style={{ backgroundImage: `url(${animeSamurai})` }}></div>
                                        <div className='netflix-thumbnail' style={{ backgroundImage: `url(${posterSpace})` }}></div>
                                        <div className='netflix-thumbnail' style={{ backgroundImage: `url(${digitalArtCyberpunkCity})` }}></div>
                                        <div className='netflix-thumbnail' style={{ backgroundImage: `url(${logoNeuronix})` }}></div>
                                        <div className='netflix-thumbnail' style={{ backgroundImage: `url(${architectureGreenCity})` }}></div>
                                        <div className='netflix-thumbnail' style={{ backgroundImage: `url(${architectureGlassTowers})` }}></div>
                                        <div className='netflix-thumbnail'></div>
                                        <div className='netflix-thumbnail'></div>
                                        <div className='netflix-thumbnail'></div>
                                        <div className='netflix-thumbnail'></div>
                                        <div className='netflix-thumbnail'></div>
                                    </div>
                                    <div className='netflix-row'>
                                        <div className='netflix-thumbnail' style={{ backgroundImage: `url(${animalElephant})` }}></div>
                                        <div className='netflix-thumbnail' style={{ backgroundImage: `url(${portraitAbstractShapes})` }}></div>
                                        <div className='netflix-thumbnail' style={{ backgroundImage: `url(${portraitBallerina})` }}></div>
                                        <div className='netflix-thumbnail' style={{ backgroundImage: `url(${portraitCyberpunkVisorGuy})` }}></div>
                                        <div className='netflix-thumbnail' style={{ backgroundImage: `url(${portraitDapperOlderGuy})` }}></div>
                                        <div className='netflix-thumbnail' style={{ backgroundImage: `url(${portraitElderlyWoman})` }}></div>
                                        <div className='netflix-thumbnail' style={{ backgroundImage: `url(${portraitFemaleWarrior})` }}></div>
                                        <div className='netflix-thumbnail' style={{ backgroundImage: `url(${portraitGraffitiArtist})` }}></div>
                                        <div className='netflix-thumbnail' style={{ backgroundImage: `url(${portraitJazzMusician})` }}></div>
                                        <div className='netflix-thumbnail' style={{ backgroundImage: `url(${portraitMiddleAgedTraveller})` }}></div>
                                        <div className='netflix-thumbnail' style={{ backgroundImage: `url(${portraitRenaissanceWoman})` }}></div>
                                        <div className='netflix-thumbnail' style={{ backgroundImage: `url(${portraitSteampunk})` }}></div>
                                        <div className='netflix-thumbnail' style={{ backgroundImage: `url(${portraitSurrealistFlowy})` }}></div>
                                        <div className='netflix-thumbnail' style={{ backgroundImage: `url(${animalLion})` }}></div>
                                        <div className='netflix-thumbnail' style={{ backgroundImage: `url(${landscapeCoastal})` }}></div>
                                        <div className='netflix-thumbnail' style={{ backgroundImage: `url(${landscapeJungle})` }}></div>
                                        <div className='netflix-thumbnail' style={{ backgroundImage: `url(${cartoonPigtailGirl})` }}></div>
                                        <div className='netflix-thumbnail' style={{ backgroundImage: `url(${cartoonSuperheroGirl})` }}></div>
                                        <div className='netflix-thumbnail' style={{ backgroundImage: `url(${cartoonTypewriter})` }}></div>
                                        <div className='netflix-thumbnail' style={{ backgroundImage: `url(${drawingMagicBook})` }}></div>
                                        <div className='netflix-thumbnail' style={{ backgroundImage: `url(${drawingTypewriter})` }}></div>
                                        <div className='netflix-thumbnail' style={{ backgroundImage: `url(${animeSpaceshipCockpit})` }}></div>
                                        <div className='netflix-thumbnail' style={{ backgroundImage: `url(${animeWarriorCherryBlossoms})` }}></div>
                                        <div className='netflix-thumbnail' style={{ backgroundImage: `url(${animeWarriorCherryBlossoms})` }}></div>
                                        <div className='netflix-thumbnail' style={{ backgroundImage: `url(${posterMusicFestival})` }}></div>
                                    </div>
                                    <div className='netflix-row'>
                                        <div className='netflix-thumbnail' style={{ backgroundImage: `url(${portraitCyberpunkVisorGuy2})` }}></div>
                                        <div className='netflix-thumbnail' style={{ backgroundImage: `url(${portraitElderlyWoman2})` }}></div>
                                        <div className='netflix-thumbnail' style={{ backgroundImage: `url(${posterScifiCity})` }}></div>
                                        <div className='netflix-thumbnail' style={{ backgroundImage: `url(${posterVintageRobot})` }}></div>
                                        <div className='netflix-thumbnail' style={{ backgroundImage: `url(${digitalArtFloatingIsland})` }}></div>
                                        <div className='netflix-thumbnail' style={{ backgroundImage: `url(${digitalArtUnderwaterCity})` }}></div>
                                        <div className='netflix-thumbnail' style={{ backgroundImage: `url(${logoDroneDelivery})` }}></div>
                                        <div className='netflix-thumbnail' style={{ backgroundImage: `url(${logoSolaris})` }}></div>
                                        <div className='netflix-thumbnail' style={{ backgroundImage: `url(${architectureCliffsideVilla})` }}></div>
                                        <div className='netflix-thumbnail' style={{ backgroundImage: `url(${architectureGlassTowers})` }}></div>
                                        <div className='netflix-thumbnail' style={{ backgroundImage: `url(${portraitDarkCurlyHair})` }}></div>
                                        <div className='netflix-thumbnail' style={{ backgroundImage: `url(${portraitAbstractShapes2})` }}></div>
                                        <div className='netflix-thumbnail' style={{ backgroundImage: `url(${portraitBallerina2})` }}></div>
                                        <div className='netflix-thumbnail' style={{ backgroundImage: `url(${portraitCyberpunkVisorGuy3})` }}></div>
                                        <div className='netflix-thumbnail' style={{ backgroundImage: `url(${portraitFemaleWarrior2})` }}></div>
                                        <div className='netflix-thumbnail' style={{ backgroundImage: `url(${portraitGraffitiArtist2})` }}></div>
                                        <div className='netflix-thumbnail' style={{ backgroundImage: `url(${portraitMiddleAgedTraveller2})` }}></div>
                                        <div className='netflix-thumbnail' style={{ backgroundImage: `url(${portraitRenaissanceWoman2})` }}></div>
                                        <div className='netflix-thumbnail' style={{ backgroundImage: `url(${portraitSurrealistSpaceFlowers})` }}></div>
                                        <div className='netflix-thumbnail' style={{ backgroundImage: `url(${cartoonSuperheroGirl2})` }}></div>
                                        <div className='netflix-thumbnail' style={{ backgroundImage: `url(${animeWarriorCherryBlossoms2})` }}></div>
                                        <div className='netflix-thumbnail' style={{ backgroundImage: `url(${cartoonSuperheroGirl2})` }}></div>
                                        <div className='netflix-thumbnail' style={{ backgroundImage: `url(${cartoonSuperheroGirl2})` }}></div>
                                        <div className='netflix-thumbnail' style={{ backgroundImage: `url(${cartoonSuperheroGirl2})` }}></div>
                                        <div className='netflix-thumbnail' style={{ backgroundImage: `url(${cartoonSuperheroGirl2})` }}></div>
                                    </div>
                                    <div className='netflix-row'>
                                        <div className='netflix-thumbnail' style={{ backgroundImage: `url(${architectureCliffsideVilla2})` }}></div>
                                        <div className='netflix-thumbnail' style={{ backgroundImage: `url(${landscapeCanada})` }}></div>
                                        <div className='netflix-thumbnail' style={{ backgroundImage: `url(${cartoonHoverboard})` }}></div>
                                        <div className='netflix-thumbnail' style={{ backgroundImage: `url(${drawingHandClock})` }}></div>
                                        <div className='netflix-thumbnail' style={{ backgroundImage: `url(${animeSamurai})` }}></div>
                                        <div className='netflix-thumbnail' style={{ backgroundImage: `url(${posterSpace})` }}></div>
                                        <div className='netflix-thumbnail' style={{ backgroundImage: `url(${digitalArtCyberpunkCity})` }}></div>
                                        <div className='netflix-thumbnail' style={{ backgroundImage: `url(${logoNeuronix})` }}></div>
                                        <div className='netflix-thumbnail' style={{ backgroundImage: `url(${architectureGreenCity})` }}></div>
                                        <div className='netflix-thumbnail' style={{ backgroundImage: `url(${architectureGlassTowers})` }}></div>
                                        <div className='netflix-thumbnail' style={{ backgroundImage: `url(${portraitDarkCurlyHair})` }}></div>
                                        <div className='netflix-thumbnail' style={{ backgroundImage: `url(${landscapeCanada})` }}></div>
                                        <div className='netflix-thumbnail' style={{ backgroundImage: `url(${cartoonHoverboard})` }}></div>
                                        <div className='netflix-thumbnail' style={{ backgroundImage: `url(${drawingHandClock})` }}></div>
                                        <div className='netflix-thumbnail' style={{ backgroundImage: `url(${animeSamurai})` }}></div>
                                        <div className='netflix-thumbnail' style={{ backgroundImage: `url(${posterSpace})` }}></div>
                                        <div className='netflix-thumbnail' style={{ backgroundImage: `url(${digitalArtCyberpunkCity})` }}></div>
                                        <div className='netflix-thumbnail' style={{ backgroundImage: `url(${logoNeuronix})` }}></div>
                                        <div className='netflix-thumbnail' style={{ backgroundImage: `url(${architectureGreenCity})` }}></div>
                                        <div className='netflix-thumbnail' style={{ backgroundImage: `url(${architectureGlassTowers})` }}></div>
                                        <div className='netflix-thumbnail'></div>
                                        <div className='netflix-thumbnail'></div>
                                        <div className='netflix-thumbnail'></div>
                                        <div className='netflix-thumbnail'></div>
                                        <div className='netflix-thumbnail'></div>
                                    </div>
                                    <div className='netflix-row'>
                                        <div className='netflix-thumbnail' style={{ backgroundImage: `url(${portraitDarkCurlyHair})` }}></div>
                                        <div className='netflix-thumbnail' style={{ backgroundImage: `url(${landscapeCanada})` }}></div>
                                        <div className='netflix-thumbnail' style={{ backgroundImage: `url(${cartoonHoverboard})` }}></div>
                                        <div className='netflix-thumbnail' style={{ backgroundImage: `url(${drawingHandClock})` }}></div>
                                        <div className='netflix-thumbnail' style={{ backgroundImage: `url(${animeSamurai})` }}></div>
                                        <div className='netflix-thumbnail' style={{ backgroundImage: `url(${posterSpace})` }}></div>
                                        <div className='netflix-thumbnail' style={{ backgroundImage: `url(${digitalArtCyberpunkCity})` }}></div>
                                        <div className='netflix-thumbnail' style={{ backgroundImage: `url(${logoNeuronix})` }}></div>
                                        <div className='netflix-thumbnail' style={{ backgroundImage: `url(${architectureGreenCity})` }}></div>
                                        <div className='netflix-thumbnail' style={{ backgroundImage: `url(${architectureGlassTowers})` }}></div>
                                        <div className='netflix-thumbnail' style={{ backgroundImage: `url(${portraitDarkCurlyHair})` }}></div>
                                        <div className='netflix-thumbnail' style={{ backgroundImage: `url(${landscapeCanada})` }}></div>
                                        <div className='netflix-thumbnail' style={{ backgroundImage: `url(${cartoonHoverboard})` }}></div>
                                        <div className='netflix-thumbnail' style={{ backgroundImage: `url(${drawingHandClock})` }}></div>
                                        <div className='netflix-thumbnail' style={{ backgroundImage: `url(${animeSamurai})` }}></div>
                                        <div className='netflix-thumbnail' style={{ backgroundImage: `url(${posterSpace})` }}></div>
                                        <div className='netflix-thumbnail' style={{ backgroundImage: `url(${digitalArtCyberpunkCity})` }}></div>
                                        <div className='netflix-thumbnail' style={{ backgroundImage: `url(${logoNeuronix})` }}></div>
                                        <div className='netflix-thumbnail' style={{ backgroundImage: `url(${architectureGreenCity})` }}></div>
                                        <div className='netflix-thumbnail' style={{ backgroundImage: `url(${architectureGlassTowers})` }}></div>
                                        <div className='netflix-thumbnail'></div>
                                        <div className='netflix-thumbnail'></div>
                                        <div className='netflix-thumbnail'></div>
                                        <div className='netflix-thumbnail'></div>
                                        <div className='netflix-thumbnail'></div>
                                    </div>
                                    <div className='netflix-row'>
                                        <div className='netflix-thumbnail'></div>
                                        <div className='netflix-thumbnail'></div>
                                        <div className='netflix-thumbnail'></div>
                                        <div className='netflix-thumbnail'></div>
                                        <div className='netflix-thumbnail'></div>
                                        <div className='netflix-thumbnail'></div>
                                        <div className='netflix-thumbnail'></div>
                                        <div className='netflix-thumbnail'></div>
                                        <div className='netflix-thumbnail'></div>
                                        <div className='netflix-thumbnail'></div>
                                        <div className='netflix-thumbnail'></div>
                                        <div className='netflix-thumbnail'></div>
                                        <div className='netflix-thumbnail'></div>
                                        <div className='netflix-thumbnail'></div>
                                        <div className='netflix-thumbnail'></div>
                                        <div className='netflix-thumbnail'></div>
                                        <div className='netflix-thumbnail'></div>
                                        <div className='netflix-thumbnail'></div>
                                        <div className='netflix-thumbnail'></div>
                                        <div className='netflix-thumbnail'></div>
                                        <div className='netflix-thumbnail'></div>
                                        <div className='netflix-thumbnail'></div>
                                        <div className='netflix-thumbnail'></div>
                                        <div className='netflix-thumbnail'></div>
                                        <div className='netflix-thumbnail'></div>
                                    </div>
                                    <div className='netflix-row'>
                                        <div className='netflix-thumbnail'></div>
                                        <div className='netflix-thumbnail'></div>
                                        <div className='netflix-thumbnail'></div>
                                        <div className='netflix-thumbnail'></div>
                                        <div className='netflix-thumbnail'></div>
                                        <div className='netflix-thumbnail'></div>
                                        <div className='netflix-thumbnail'></div>
                                        <div className='netflix-thumbnail'></div>
                                        <div className='netflix-thumbnail'></div>
                                        <div className='netflix-thumbnail'></div>
                                        <div className='netflix-thumbnail'></div>
                                        <div className='netflix-thumbnail'></div>
                                        <div className='netflix-thumbnail'></div>
                                        <div className='netflix-thumbnail'></div>
                                        <div className='netflix-thumbnail'></div>
                                        <div className='netflix-thumbnail'></div>
                                        <div className='netflix-thumbnail'></div>
                                        <div className='netflix-thumbnail'></div>
                                        <div className='netflix-thumbnail'></div>
                                        <div className='netflix-thumbnail'></div>
                                        <div className='netflix-thumbnail'></div>
                                        <div className='netflix-thumbnail'></div>
                                        <div className='netflix-thumbnail'></div>
                                        <div className='netflix-thumbnail'></div>
                                        <div className='netflix-thumbnail'></div>
                                    </div>
                                    <div className='netflix-row'>
                                        <div className='netflix-thumbnail'></div>
                                        <div className='netflix-thumbnail'></div>
                                        <div className='netflix-thumbnail'></div>
                                        <div className='netflix-thumbnail'></div>
                                        <div className='netflix-thumbnail'></div>
                                        <div className='netflix-thumbnail'></div>
                                        <div className='netflix-thumbnail'></div>
                                        <div className='netflix-thumbnail'></div>
                                        <div className='netflix-thumbnail'></div>
                                        <div className='netflix-thumbnail'></div>
                                        <div className='netflix-thumbnail'></div>
                                        <div className='netflix-thumbnail'></div>
                                        <div className='netflix-thumbnail'></div>
                                        <div className='netflix-thumbnail'></div>
                                        <div className='netflix-thumbnail'></div>
                                        <div className='netflix-thumbnail'></div>
                                        <div className='netflix-thumbnail'></div>
                                        <div className='netflix-thumbnail'></div>
                                        <div className='netflix-thumbnail'></div>
                                        <div className='netflix-thumbnail'></div>
                                        <div className='netflix-thumbnail'></div>
                                        <div className='netflix-thumbnail'></div>
                                        <div className='netflix-thumbnail'></div>
                                        <div className='netflix-thumbnail'></div>
                                        <div className='netflix-thumbnail'></div>
                                    </div>
                                    <div className='netflix-row'>
                                        <div className='netflix-thumbnail'></div>
                                        <div className='netflix-thumbnail'></div>
                                        <div className='netflix-thumbnail'></div>
                                        <div className='netflix-thumbnail'></div>
                                        <div className='netflix-thumbnail'></div>
                                        <div className='netflix-thumbnail'></div>
                                        <div className='netflix-thumbnail'></div>
                                        <div className='netflix-thumbnail'></div>
                                        <div className='netflix-thumbnail'></div>
                                        <div className='netflix-thumbnail'></div>
                                        <div className='netflix-thumbnail'></div>
                                        <div className='netflix-thumbnail'></div>
                                        <div className='netflix-thumbnail'></div>
                                        <div className='netflix-thumbnail'></div>
                                        <div className='netflix-thumbnail'></div>
                                        <div className='netflix-thumbnail'></div>
                                        <div className='netflix-thumbnail'></div>
                                        <div className='netflix-thumbnail'></div>
                                        <div className='netflix-thumbnail'></div>
                                        <div className='netflix-thumbnail'></div>
                                        <div className='netflix-thumbnail'></div>
                                        <div className='netflix-thumbnail'></div>
                                        <div className='netflix-thumbnail'></div>
                                        <div className='netflix-thumbnail'></div>
                                        <div className='netflix-thumbnail'></div>
                                    </div>
                                    <div className='netflix-row'>
                                        <div className='netflix-thumbnail'></div>
                                        <div className='netflix-thumbnail'></div>
                                        <div className='netflix-thumbnail'></div>
                                        <div className='netflix-thumbnail'></div>
                                        <div className='netflix-thumbnail'></div>
                                        <div className='netflix-thumbnail'></div>
                                        <div className='netflix-thumbnail'></div>
                                        <div className='netflix-thumbnail'></div>
                                        <div className='netflix-thumbnail'></div>
                                        <div className='netflix-thumbnail'></div>
                                        <div className='netflix-thumbnail'></div>
                                        <div className='netflix-thumbnail'></div>
                                        <div className='netflix-thumbnail'></div>
                                        <div className='netflix-thumbnail'></div>
                                        <div className='netflix-thumbnail'></div>
                                        <div className='netflix-thumbnail'></div>
                                        <div className='netflix-thumbnail'></div>
                                        <div className='netflix-thumbnail'></div>
                                        <div className='netflix-thumbnail'></div>
                                        <div className='netflix-thumbnail'></div>
                                        <div className='netflix-thumbnail'></div>
                                        <div className='netflix-thumbnail'></div>
                                        <div className='netflix-thumbnail'></div>
                                        <div className='netflix-thumbnail'></div>
                                        <div className='netflix-thumbnail'></div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className='artist-content-container' ref={artistContentContainerRef}>
                        <div className='artist-title-wrapper'>
                            <p className="feature-title">Artist.</p>
                            <p className="feature-title-2">Unleash your imagination.</p>
                        </div>
                        <p className='artist-left-text'>
                            Bring your creative visions to life right from your browser. Create realistic portraits, stunning landscapes, unique logos, and more. Copilot contains 6 fine-tuned image models alongside DALL-E 3 for every artistic need. Unlock the potential of professional-quality art, no matter your skill level. All within your sidebar, on any site.
                        </p>
                    </div>



                    <div className="youtube-container">
                        <div ref={youtubeTextRef}  className='product-text-wrapper'>
                            <p className="feature-title">YouTube 2.0.</p>
                            <p className="feature-title-2">Absorb the world's video library.</p>
                        </div>
                        <div className='youtube-features-container'>
                            <div ref={youtubeImg1Ref}  className="youtube-feature-container" data-animation-delay="0.2">
                            </div>
                            <div ref={youtubeImg2Ref}  className="youtube-feature-container" data-animation-delay="0.4">
                            
                            </div>
                        </div>
                    </div>
                </div>

                <div className="about-section">
                    <p ref={aboutTitleRef} className="about-title">What we stand for.</p>
                    <div ref={aboutContainerRef} className='about-container'>
                        <div className='accordion-container'>
                            <div className="mission-section" onClick={() => toggleSection('mission')}
                                style={{
                                    cursor: openSection === 'mission' ? 'default' : 'pointer',
                                }}
                            >
                                <div className="accordion-title-section">
                                    <p className="accordion-title">The mission</p>
                                    <div className={`accordion-arrow ${openSection === 'mission' ? 'open' : ''}`}></div>
                                </div>
                                <div
                                    ref={missionRef}
                                    className={`accordion-content mission-text ${openSection === 'mission' ? 'open' : ''}`}
                                >
                                    <p>
                                        To gameify your personal development to make it as frictionless, satisfying, and effective as possible.
                                    </p>
                                </div>
                            </div>
                            <div className='seperator'></div>
                            <div className="privacy-section" onClick={() => toggleSection('privacy')}
                                style={{
                                    cursor: openSection === 'privacy' ? 'default' : 'pointer',
                                }}
                            >
                                <div className="accordion-title-section">
                                    <p className="accordion-title">Privacy and security</p>
                                    <div className={`accordion-arrow ${openSection === 'privacy' ? 'open' : ''}`}></div>
                                </div>
                                <div
                                    ref={privacyRef}
                                    className={`accordion-content privacy-text ${openSection === 'privacy' ? 'open' : ''}`}
                                >
                                    <p>
                                        Paradigm was designed with your privacy in mind. We are committed to protecting your data and will never sell it to a third party.
                                    </p>
                                </div>
                            </div>
                            <div className='seperator'></div>
                            <div className="ethos-section" onClick={() => toggleSection('ethos')}
                                style={{
                                    cursor: openSection === 'ethos' ? 'default' : 'pointer',
                                }}
                            >
                                <div className="accordion-title-section">
                                    <p className="accordion-title">AI ethos</p>
                                    <div className={`accordion-arrow ${openSection === 'ethos' ? 'open' : ''}`}></div>
                                </div>
                                <div
                                    ref={ethosRef}
                                    className={`accordion-content ethos-text ${openSection === 'ethos' ? 'open' : ''}`}
                                >
                                    <p>
                                        Our AI ethos emphasizes ethical design and transparency in every tool we build. For this reason, we are a community led platform that supports the democratisation of AI.
                                    </p>
                                </div>
                            </div>
                        </div>
                        {/* Display the appropriate image based on the currently open section */}
                        <img className="section-image" src={getImage()} alt="Section Visual" />
                    </div>
                </div>

                <div className="testimonials-section">
                    <p ref={testimonialTitleRef} className="testimonials-title">The Paradigm community.</p>
                    <div className="testimonials-container">
                        {width > 912 ? (
                        // Desktop view: 4 columns
                            <>
                                <div className="testimonials-column">
                                    <div ref={testimonial1Ref} className="testimonial turquoise-gradient" data-animation-delay="0.2">
                                        <div>
                                            <i className="bi bi-quote"></i>
                                        </div>
                                        <p className="testimonial-text">
                                            This has absolutely revolutionized how I learn and how I work. I have been able to use the My Tutor feature to further my language learning journey, develop emails for work with acute articulation and speed, and best of all it's been teaching me how to program. 
                                        </p>
                                    </div>
                                    <div ref={testimonial2Ref} className="testimonial yellow-gradient" data-animation-delay="0.4">
                                        <div>
                                            <i className="bi bi-quote"></i>
                                        </div>
                                        <p className="testimonial-text">
                                            The My Tutor Copilot AI Assistant is 1000x better than Copilot by itself.
                                        </p>
                                    </div>
                                </div>
                                <div className="testimonials-column">
                                    <div ref={testimonial3Ref} className="testimonial green-gradient" data-animation-delay="0.4">
                                        <div>
                                            <i className="bi bi-quote"></i>
                                        </div>
                                        <p className="testimonial-text">
                                            I'm an A-level student and I can't even tell you how many hours of revision I have saved by having copilot simplify the topics for me. My teachers always recommend so many long videos to watch, I don't know how I lived without YouTube vision before, or why YouTube doesn't already have those features tbh.
                                        </p>
                                    </div>
                                    <div ref={testimonial4Ref} className="testimonial purple-gradient" data-animation-delay="0.6">
                                        <div>
                                            <i className="bi bi-quote"></i>
                                        </div>
                                        <p className="testimonial-text">
                                            Originally I was quite skeptical about using an AI on my browser but I've found copilot to really help me out in ways I hadn't originally thought possible. It really helps alleviate the extra stress from being at uni and would definitely recommend for others wanting to save time and discover different options for their desktop.
                                        </p>
                                    </div>
                                </div>
                                <div className="testimonials-column">
                                    <div ref={testimonial5Ref} className="testimonial pink-gradient" data-animation-delay="0.6">
                                        <div>
                                            <i className="bi bi-quote"></i>
                                        </div>
                                        <p className="testimonial-text">
                                            This thing is insane. Seriously, I am so incredibly grateful to the people who spent time to make this. I cannot stress enough how much I love the My Tutor feature in this specific extension. I've been telling literally everyone I know about it. I've gotten my coworkers and family members to try it too. 
                                        </p>
                                    </div>
                                    <div ref={testimonial6Ref} className="testimonial orange-gradient" data-animation-delay="0.8">
                                        <div>
                                            <i className="bi bi-quote"></i>
                                        </div>
                                        <p className="testimonial-text">
                                            This is genius! I always use YouTube tutorials to learn, reading the key points and the moment finder has changed my life and saves me so much time so I'm very productive with my time. Recommended to all friends and family they love it too. It's just better.
                                        </p>
                                    </div>
                                </div>
                                <div className="testimonials-column">
                                    <div ref={testimonial7Ref} className="testimonial red-gradient" data-animation-delay="0.8">
                                        <div>
                                            <i className="bi bi-quote"></i>
                                        </div>
                                        <p className="testimonial-text">
                                            I'm a student at university and came across this on titktok. In love with it atm, studying business and this helps out loads with my essays and research. Takes the pressure off and makes it fun
                                        </p>
                                    </div>
                                    <div ref={testimonial8Ref} className="testimonial blue-gradient" data-animation-delay="1">
                                        <div>
                                            <i className="bi bi-quote"></i>
                                        </div>
                                        <p className="testimonial-text">
                                            Fantastic product, fast and easy information right at your fingertips. The Youtube vision feature is a game changer if you are trying to learn through video and sift through the waffle.
                                        </p>
                                    </div>
                                </div>
                            </>
                        ) : width > 540 ? (
                            // Tablet view: 3 columns
                            <>
                                <div className="testimonials-column">
                                    {/* 1st Column Testimonials */}
                                    <div ref={testimonial1Ref} className="testimonial turquoise-gradient" data-animation-delay="0.2">
                                        <div>
                                            <i className="bi bi-quote"></i>
                                        </div>
                                        <p className="testimonial-text">
                                            This has absolutely revolutionized how I learn and how I work. I have been able to use the My Tutor feature to further my language learning journey, develop emails for work with acute articulation and speed, and best of all it's been teaching me how to program. 
                                        </p>
                                    </div>
                                    <div ref={testimonial2Ref} className="testimonial yellow-gradient" data-animation-delay="0.4">
                                        <div>
                                            <i className="bi bi-quote"></i>
                                        </div>
                                        <p className="testimonial-text">
                                            The My Tutor Copilot AI Assistant is 1000x better than Copilot by itself.
                                        </p>
                                    </div>
                                    <div ref={testimonial3Ref} className="testimonial red-gradient" data-animation-delay="0.6">
                                        <div>
                                            <i className="bi bi-quote"></i>
                                        </div>
                                        <p className="testimonial-text">
                                            I'm a student at university and came across this on titktok. In love with it atm, studying business and this helps out loads with my essays and research. Takes the pressure off and makes it fun
                                        </p>
                                    </div>
                                </div>
                                <div className="testimonials-column">
                                    {/* 2nd Column Testimonials */}
                                    <div ref={testimonial4Ref} className="testimonial green-gradient" data-animation-delay="0.4">
                                        <div>
                                            <i className="bi bi-quote"></i>
                                        </div>
                                        <p className="testimonial-text">
                                            I'm an A-level student and I can't even tell you how many hours of revision I have saved by having copilot simplify the topics for me. My teachers always recommend so many long videos to watch, I don't know how I lived without YouTube vision before, or why YouTube doesn't already have those features tbh.
                                        </p>
                                    </div>
                                    <div ref={testimonial5Ref} className="testimonial purple-gradient" data-animation-delay="0.6">
                                        <div>
                                            <i className="bi bi-quote"></i>
                                        </div>
                                        <p className="testimonial-text">
                                            Originally I was quite skeptical about using an AI on my browser but I've found copilot to really help me out in ways I hadn't originally thought possible. It really helps alleviate the extra stress from being at uni and would definitely recommend for others wanting to save time and discover different options for their desktop.
                                        </p>
                                    </div>
                                    <div ref={testimonial6Ref} className="testimonial blue-gradient" data-animation-delay="0.8">
                                        <div>
                                            <i className="bi bi-quote"></i>
                                        </div>
                                        <p className="testimonial-text">
                                            Fantastic product, fast and easy information right at your fingertips. The Youtube vision feature is a game changer if you are trying to learn through video and sift through the waffle.
                                        </p>
                                    </div>
                                </div>
                                <div className="testimonials-column">
                                    {/* 3rd Column Testimonials */}
                                    <div ref={testimonial7Ref} className="testimonial pink-gradient" data-animation-delay="0.6">
                                        <div className="testimonial pink-gradient">
                                            <div>
                                                <i className="bi bi-quote"></i>
                                            </div>
                                            <p className="testimonial-text">
                                                This thing is insane. Seriously, I am so incredibly grateful to the people who spent time to make this. I cannot stress enough how much I love the My Tutor feature in this specific extension. I've been telling literally everyone I know about it. I've gotten my coworkers and family members to try it too. 
                                            </p>
                                        </div>
                                    </div>
                                    <div ref={testimonial8Ref} className="testimonial orange-gradient" data-animation-delay="0.8">
                                        <div>
                                            <i className="bi bi-quote"></i>
                                        </div>
                                        <p className="testimonial-text">
                                            This is genius! I always use YouTube tutorials to learn, reading the key points and the moment finder has changed my life and saves me so much time so I'm very productive with my time. Recommended to all friends and family they love it too. It's just better.
                                        </p>
                                    </div>
                                </div>
                            </>
                        ) : (
                            // Mobile view: 2 columns
                            <>
                                <div className="testimonials-column">
                                    <div ref={testimonial1Ref} className="testimonial turquoise-gradient" data-animation-delay="0.2">
                                        <div>
                                            <i className="bi bi-quote"></i>
                                        </div>
                                        <p className="testimonial-text">
                                            This has absolutely revolutionized how I learn and how I work. I have been able to use the My Tutor feature to further my language learning journey, develop emails for work with acute articulation and speed, and best of all it's been teaching me how to program. 
                                        </p>
                                    </div>
                                    <div ref={testimonial2Ref} className="testimonial yellow-gradient" data-animation-delay="0.4">
                                        <div>
                                            <i className="bi bi-quote"></i>
                                        </div>
                                        <p className="testimonial-text">
                                            The My Tutor Copilot AI Assistant is 1000x better than Copilot by itself.
                                        </p>
                                    </div>
                                    <div ref={testimonial3Ref} className="testimonial green-gradient" data-animation-delay="0.6">
                                        <div>
                                            <i className="bi bi-quote"></i>
                                        </div>
                                        <p className="testimonial-text">
                                            I'm an A-level student and I can't even tell you how many hours of revision I have saved by having copilot simplify the topics for me. My teachers always recommend so many long videos to watch, I don't know how I lived without YouTube vision before, or why YouTube doesn't already have those features tbh.
                                        </p>
                                    </div>
                                    <div ref={testimonial4Ref} className="testimonial purple-gradient" data-animation-delay="0.8">
                                        <div>
                                            <i className="bi bi-quote"></i>
                                        </div>
                                        <p className="testimonial-text">
                                            Originally I was quite skeptical about using an AI on my browser but I've found copilot to really help me out in ways I hadn't originally thought possible. It really helps alleviate the extra stress from being at uni and would definitely recommend for others wanting to save time and discover different options for their desktop.
                                        </p>
                                    </div>
                                </div>
                                <div className="testimonials-column">
                                    <div ref={testimonial5Ref} className="testimonial pink-gradient" data-animation-delay="0.4">
                                        <div>
                                            <i className="bi bi-quote"></i>
                                        </div>
                                        <p className="testimonial-text">
                                            This thing is insane. Seriously, I am so incredibly grateful to the people who spent time to make this. I cannot stress enough how much I love the My Tutor feature in this specific extension. I've been telling literally everyone I know about it. I've gotten my coworkers and family members to try it too. 
                                        </p>
                                    </div>
                                    <div ref={testimonial6Ref} className="testimonial orange-gradient" data-animation-delay="0.6">
                                        <div>
                                            <i className="bi bi-quote"></i>
                                        </div>
                                        <p className="testimonial-text">
                                            This is genius! I always use YouTube tutorials to learn, reading the key points and the moment finder has changed my life and saves me so much time so I'm very productive with my time. Recommended to all friends and family they love it too. It's just better.
                                        </p>
                                    </div>
                                    <div ref={testimonial7Ref} className="testimonial red-gradient" data-animation-delay="0.8">
                                        <div>
                                            <i className="bi bi-quote"></i>
                                        </div>
                                        <p className="testimonial-text">
                                            I'm a student at university and came across this on titktok. In love with it atm, studying business and this helps out loads with my essays and research. Takes the pressure off and makes it fun
                                        </p>
                                    </div>
                                    <div ref={testimonial8Ref} className="testimonial blue-gradient" data-animation-delay="1">
                                        <div>
                                            <i className="bi bi-quote"></i>
                                        </div>
                                        <p className="testimonial-text">
                                            Fantastic product, fast and easy information right at your fingertips. The Youtube vision feature is a game changer if you are trying to learn through video and sift through the waffle.
                                        </p>
                                    </div>
                                </div>
                            </>
                        )}
                    </div>
                    <div ref={testimonialButtonRef} className="community-button-container">
                        <button className="product-button button-secondary fill-available-button" onClick={() => alert('Login')}>
                            Join the Paradigm community
                        </button>
                    </div>
                </div>

                <div className="plans-section">
                    <p ref={plansTitleRef} className="plans-section-title">
                        A perfect tutor for everyone.
                    </p>
                    <div className="plan-table-container">
                        <div ref={novicePlanCardRef} className="plan-card novice-plan-card" data-animation-delay="0">
                            <div className="plan-card-top-section">
                                <div className="plan-text-container">
                                    <p className="plan-title novice-title">Novice</p>
                                    <p className="plan-body">Limited access. If you're new to Paradigm, we recommend starting with the Pro plan's 14 day trial.</p>
                                </div>
                                <div className="price-and-button-container">
                                    <div className="plan-price-container">
                                        <p className="plan-price">£0</p>
                                        <div className="per-month-container">
                                            <p className="per-month-text">per</p>
                                            <p className="per-month-text">month</p>
                                        </div>
                                    </div>
                                    <button className="button-primary plan-button" onClick={() => alert('Novice!')}>
                                        Try Paradigm Novice
                                    </button>
                                </div>
                            </div>
                            <div className="plan-includes-container">
                                <span className="includes-title">Here's what you get each month.</span>
                                <div className="includes-item">
                                    <i className="bi bi-lightning-charge-fill"></i>
                                    <span className="includes-text"> 900 GPT-3.5 messages</span>
                                </div>
                                <div className="includes-item">
                                    <i className="bi bi-youtube"></i>
                                    <span className="includes-text"> 15 hours of video processing</span>
                                </div>
                                <div className="includes-item">
                                    <i className="bi bi-search"></i>
                                    <span className="includes-text">900 Copilot searches</span>
                                </div>
                                <div className="includes-item">
                                    <i className="bi bi-x-circle"></i>
                                    <span className="includes-text">No voice activations</span>
                                </div>
                                <div className="includes-item">
                                    <i className="bi bi-x-circle"></i>
                                    <span className="includes-text">No GPT-4 access</span>
                                </div>
                            </div>
                        </div>
                        <div ref={proPlanCardRef} className="plan-card pro-plan-card" data-animation-delay="0.2">
                            <div className="plan-card-top-section">
                                <div className="plan-text-container">
                                    <p className="plan-title pro-title">Pro</p>
                                    <p className="plan-body">Try our full suite of features for free. Cancel anytime. We'll remind you 7 days before your trial ends.</p>
                                </div>
                                <div className="price-and-button-container">
                                    <div className="plan-price-container">
                                        <p className="plan-price">£9.99</p>
                                        <div className="per-month-container">
                                            <p className="per-month-text">per</p>
                                            <p className="per-month-text">month</p>
                                        </div>
                                    </div>
                                    <button className="button-primary plan-button" onClick={() => alert('Pro!')}>
                                        Try free Pro trial
                                    </button>
                                </div>
                            </div>
                            <div className="plan-includes-container">
                                <span className="includes-title">Here's what you get each month.</span>
                                <div className="includes-item">
                                    <i className="bi bi-lightning-charge-fill"></i>
                                    <span className="includes-text"> 30,000 GPT-3.5 messages</span>
                                </div>
                                <div className="includes-item">
                                    <i className="bi bi-youtube"></i>
                                    <span className="includes-text"> 500 hours of video processing</span>
                                </div>
                                <div className="includes-item">
                                    <i className="bi bi-search"></i>
                                    <span className="includes-text">30,000 Copilot searches</span>
                                </div>
                                <div className="includes-item">
                                    <i className="bi bi-volume-up-fill"></i>
                                    <span className="includes-text">600 voice activations</span>
                                </div>
                                <div className="includes-item">
                                    <i className="bi bi-stars"></i>
                                    <span className="includes-text">600 GPT-4o access</span>
                                </div>
                            </div>
                        </div>
                        <div ref={ultraPlanCardRef} className="plan-card ultra-plan-card" data-animation-delay="0.4">
                            <div className="plan-card-top-section">
                                <div className="plan-text-container">
                                    <p className="plan-title ultra-title">Ultra</p>
                                    <p className="plan-body">Paradigm at its very best. Maximum power. Maximum productivity.</p>
                                </div>
                                <div className="price-and-button-container">
                                    <div className="plan-price-container">
                                        <p className="plan-price">£24.99</p>
                                        <div className="per-month-container">
                                            <p className="per-month-text">per</p>
                                            <p className="per-month-text">month</p>
                                        </div>
                                    </div>
                                    <button className="button-primary plan-button" onClick={() => alert('Ultra!')}>
                                        Try Paradigm Ultra
                                    </button>
                                </div>
                            </div>
                            <div className="plan-includes-container">
                                <span className="includes-title">Here's what you get each month.</span>
                                <div className="includes-item">
                                    <i className="bi bi-lightning-charge-fill"></i>
                                    <span className="includes-text"> 75,000 GPT-3.5 messages</span>
                                </div>
                                <div className="includes-item">
                                    <i className="bi bi-youtube"></i>
                                    <span className="includes-text"> 1,250 hours of video processing</span>
                                </div>
                                <div className="includes-item">
                                    <i className="bi bi-search"></i>
                                    <span className="includes-text">75,000 Copilot searches</span>
                                </div>
                                <div className="includes-item">
                                    <i className="bi bi-volume-up-fill"></i>
                                    <span className="includes-text">1,500 voice activations</span>
                                </div>
                                <div className="includes-item">
                                    <i className="bi bi-stars"></i>
                                    <span className="includes-text">1,500 GPT-4o access</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="footer-section">
                    <div className="footer-seperator"></div>
                    <div className="footer-content-container">
                        <p>© 2024 All Rights Reserved, Paradigm®</p>
                        <div className="terms-container">
                            <p>Privacy policy</p>
                            <p>|</p>
                            <p>Terms of use</p>
                        </div>
                    </div>
                </div>
            </div>

            <div className={`bottom-banner ${showBanner ? 'show' : 'hide'}`}>
                <p className="bottom-banner-text">The personal tutor you've always wanted.</p>
                <button className="button-primary" onClick={() => alert('Get started!')}>
                    Accept free trial
                </button>
            </div>

        </div>
    );
}

export default LandingPage4;